<template>
  <b-container
    fluid
  >
    <BreadCrumb
      v-if="req"
      :items=" [
        {
          text: 'Inicio',
          href: '/',
        }, {
          text: 'Detalhes Estabelecimento',
          href: `/plataforma-credito/estabelecimento/detalhes/${getStoreMerchant().id}`,
        }, {
          text: 'Agenda',
        }]"
    />

    <header-merchant class="p-0" />

    <b-card
      v-if="req"
      title="Dados do Opt-in"
    >

      <Form
        :disabled-all="true"
        :req="req"
      />
    </b-card>

    <Table
      v-if="req"
      :office-hours="officeHour"
      button-title="Nova Agenda"
      :table-data="req.schedule_searches"
      :fields="consultFields"
      :edit="false"
      :details="true"
      new-title="Nova Consulta"
      redirect="consulta"
      title="Agenda"
      :new-type="!!req.is_active"
      :new-href="`/plataforma-credito/${req.register}/optin/${req.id}/agenda`"
    />

  </b-container>
</template>

<script>
import {
    BCard,
    BContainer,
} from 'bootstrap-vue';

import '@validations';

import Ripple from 'vue-ripple-directive';
import BreadCrumb from '@/views/components/BreadCrumb.vue';

import schemes from '@/utils/paymentSchemes';

import OptInService from '@/services/osiris/OptInService';
import CercOptInService from '@/services/osiris/Cerc/OptInService';

import { Crypto } from '@/libs/crypto';

import HeaderMerchant from '@/views/pages/CreditPlatform/Merchants/Details/components/Header.vue';

import TagMapper from '../mappers/Tag';
import CercMapper from '../mappers/Cerc';

import Table from '../../Merchants/Details/components/Table.vue';

import Form from '../components/Form.vue';
import { officeHours } from '@/utils/office-hours';

const mappers = {
    TAG: TagMapper,
    CERC: CercMapper,
};

const services = {
    TAG: OptInService,
    CERC: CercOptInService,
};

export default {
    components: {
        Table,
        Form,
        BCard,
        BContainer,
        BreadCrumb,
        HeaderMerchant,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            consultFields: [
                { key: 'id', label: 'ID' },
                { key: 'reference_date', label: 'Inclusão' },
                { key: 'start_date', label: 'Inicio' },
                { key: 'end_date', label: 'Final' },
                { key: 'liquidated_amount', label: 'Liquidado' },
                { key: 'uncommitted_amount', label: 'Liquidar' },
                { key: 'total_amount', label: 'Total' },
                { key: 'has_contract_effects', label: 'Efeito' },
                { key: 'actions', label: 'Ações' },
            ],
            isEdit: false,
            merchantId: 0,
            officeHour: officeHours.consult,
            schemesOptions: schemes.map(scheme => ({
                text: scheme, value: scheme,
            })),
            register: 'TAG',
            req: null,
        };
    },

    computed: {
        registerOptions() {
            const availableRegisters = JSON.parse(Crypto.decrypt(sessionStorage.getItem('register')));

            return ['CERC', 'TAG'].filter(item => availableRegisters.includes(item));
        },
    },
    async created() {
        this.isLoading(true);
        const { id } = this.$route.params;

        const servicesInArr = Object.entries(services);

        const promises = servicesInArr.map(async ([key, service]) => {
            const item = await service.getSchedules(id);
            item.data.register = key;

            if (item.data.schedule_searches) {
                item.data.schedule_searches = item.data.schedule_searches.map(item2 => ({
                    ...item2,
                    register: key,
                })).reverse();
            }
            return item;
        });

        const response = await Promise.all(promises);
        const { data } = response.find(({ status }) => status === 200);
        this.req = mappers[data.register].toView(data);

        this.isLoading(false);
    },

    methods: {

    },
};
</script>
    <style lang="scss" >

    .vs__deselect {
        fill: rgba(255, 255, 255, 0.9) !important;
    }

    .ec-tag {
      padding: 0.51rem 1rem !important;
    }

    .b-form-tags-button {
      display: none !important;
    }
    </style>
